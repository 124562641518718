import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity} from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { FlashList } from "@shopify/flash-list";
import * as queries from '../graphql/queries'

const {width, height} = Dimensions.get('screen')

const Approvals = () => {

    const navigation = useNavigation();

    const [events, setEvents] = useState([])

    const listingEvents = async() => {
      

        const allTodos = await API.graphql({query: queries.listEvents, variables: 
            {
            filter : { and: [ 
                {startStatus: {eq: 'RequestedFunds'}}             
            ]}
        } });
        
        const todos = allTodos.data.listEvents.items
        setEvents(todos)


    }


    const renderEvents = ({item})=> {
        return(
            <TouchableOpacity style={{flexDirection: 'row', marginTop: 10, height: 60}}
                onPress={()=> navigation.navigate('ApprovalsDetails', {item: item})}
            >
                <Image 
                    source={require('../../assets/icon.png')}
                    style={{height: 60, width: 80, resizeMode: 'contain'}}
                />
                <View>
                    <Text style={{fontSize: 12}}>{item.name}</Text>
                    <Text style={{fontSize: 12}}>Ended : {String(item.updatedAt).slice(0,10)}</Text>
                    <Text style={{fontSize: 12,}}>Event ID : {String(item.id).slice(0,10)}</Text>
                </View>
                
            </TouchableOpacity>
        )
    }


    const itemSeperator = () => {
        return(
            <View style={{height: 5, backgroundColor: '#e3eefa', opacity: 0.3}} />
        )
    }

useEffect(() => {

    listingEvents();
  }, [events])

    events.sort(function(a, b) {

    const newerDate = String(a.createdAt).replace(/\D/g, "")
    const newerB = String(b.createdAt).replace(/\D/g, "")

return parseFloat(newerB) - parseFloat(newerDate);
    });


  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
      <View style={{height: 60, width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Cash Approvals</Text>
                </Animated.View>
                
        </View>

        <View style={{backgroundColor: '#fcfcfc', height: height - 60 - 80, marginTop: 5}}>
            <FlashList 
                data={events}
                estimatedItemSize={100}
                renderItem={renderEvents}
                ItemSeparatorComponent={itemSeperator}
            />
        </View>
    </View>
  )
}

export default Approvals

