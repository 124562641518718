import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import LottieView from 'lottie-react-native'; 

const {width, height} = Dimensions.get('screen');


const Sales = () => {


    const navigation = useNavigation();

    const [allQueries, setQueries] = useState([])

    if(allQueries?.filter(cp => !cp.product).length === 0){
        return  (
            <Animated.View style={{alignItems: 'center', backgroundColor: 'white',  marginTop: 0, flex: 1, padding: 30}}>
                <LottieView
                        autoPlay
                        style={{
                        width: width / 3,
                        height: width / 3,
                        backgroundColor: 'transparent',
                        marginTop: 0
                    }}
                        source={require('../../assets/lottie/purpledog.json')}
                        />    
                    <Text style={{alignSelf: 'center', fontSize: 16, fontWeight: 'bold', color: '#233f4d'}}>You have no Sales</Text>
                   
               
            </Animated.View>
        )
    }


    return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
           


        </View>
    )

}
export default Sales