import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import * as mutations from '../graphql/mutations';
import { API, Auth, graphqlOperation } from "aws-amplify";


const {width, height} = Dimensions.get('screen');


const HomeScreen = () => {

    const mainImage = 'https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    const navigation = useNavigation()


    const createTest = async()=> {

        console.warn('test')

        const userDataInpute = {
            id: 100,
            firstName: 'Testing first name'
        }

        try {
             await API.graphql(graphqlOperation(mutations.createUserData, { input: userDataInpute})).then((ee)=> {
            console.warn('done')
        }) 

        } catch (error) {
            console.error(error)
        }
       



    }





    return(
        <View style={{flex: 1, backgroundColor: '#e8e8e8'}}>
            <ImageBackground style={{height: height/5, width: width}} source={{uri: mainImage}}>
                <LinearGradient colors={['transparent', 'rgba(0,0,0,0.999999999999)']} style={{width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25, paddingBottom: 25}}>
                    <Text style={{color: '#fcfcfc', fontSize: 20, fontWeight: 'bold', width: width / 2, marginTop: 40}}>What's an event</Text>
                    <View style={{width: width / 2, flexDirection: 'row'}}>
                        <View style={{ backgroundColor: 'purple', paddingLeft: 5, paddingRight: 5, marginRight: 5}}>
                            <Text style={{color: '#fcfcfc', fontSize: 20, fontWeight: 'bold', }}>without</Text> 
                        </View>
                        <Text style={{color: '#fcfcfc', fontSize: 20, fontWeight: 'bold', }}>us ?</Text>
                    </View>
                    
                </LinearGradient>
            </ImageBackground>

            <ScrollView>

            <View style={{height: 100, width: '100%', paddingLeft: 25, paddingRight: 25, alignItems: 'center', flexDirection: 'row'}}>
                <TouchableOpacity style={{height: 60, width: 60, backgroundColor: 'purple', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>0</Text>
                    <Text style={{color: '#fcfcfc', fontSize: 10}}>Users</Text>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 60, width: 60, marginLeft: 25, backgroundColor: 'green', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>0</Text>
                    <Text style={{color: '#fcfcfc', fontSize: 10}}>Organisers</Text>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 60, width: 60, marginLeft: 25, backgroundColor: 'green', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>0</Text>
                    <Text style={{color: '#fcfcfc', fontSize: 10}}>Campaigns</Text>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 60, width: 60, marginLeft: 25, backgroundColor: 'green', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>0</Text>
                    <Text style={{color: '#fcfcfc', fontSize: 10}}>Events</Text>
                </TouchableOpacity>
            </View>

            <View style={{paddingLeft: 15, paddingRight: 15, marginBottom: 30}}>
                <View style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 15, paddingBottom: 15, backgroundColor: '#fcfcfc', marginTop: 5, borderRadius: 20}}>
                    <TouchableOpacity onPress={()=> navigation.navigate('Banners')} style={{height: 60, width: '100%', backgroundColor: 'transparent', justifyContent: 'space-between', borderRadius: 10, borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Feather name="calendar" size={0}/>
                            <Feather name= 'calendar' size={18}/>
                            <View style={{marginLeft: 20}}>
                                <Text style={{fontWeight: 'bold'}}>Banners</Text>
                                <Text style={{fontSize: 12, color: 'gray'}}>Top Sliding Banners</Text>
                            </View>
                            
                        </View>

                        <Feather name="chevron-right" size={20} color="black" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> navigation.navigate('Aone')} style={{height: 60, width: '100%', backgroundColor: 'transparent', justifyContent: 'space-between', borderRadius: 10, borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Feather name= 'alert-octagon' size={18}/>
                            <View style={{marginLeft: 20}}>
                                <Text style={{fontWeight: 'bold'}}>A1 Section</Text>
                                <Text style={{fontSize: 12, color: 'gray'}}>Trending in South Africa</Text>
                            </View>
                        </View>

                        <Feather name="chevron-right" size={20} color="black" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> navigation.navigate('Atwo')} style={{height: 60, width: '100%', backgroundColor: 'transparent', justifyContent: 'space-between', borderRadius: 10, borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Feather name="calendar" size={18}/>
                            <View style={{marginLeft: 20}}>
                                <Text style={{fontWeight: 'bold'}}>A2 Section</Text>
                                <Text style={{fontSize: 12, color: 'gray'}}>Weekend Ziyawa</Text>
                            </View>
                        </View>

                        <Feather name="chevron-right" size={20} color="black" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> navigation.navigate('Athree')} style={{height: 60, width: '100%', backgroundColor: 'transparent', justifyContent: 'space-between', borderRadius: 10, borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Feather name="calendar" size={18}/>
                            <View style={{marginLeft: 20}}>
                                <Text style={{fontWeight: 'bold'}}>A3 Section</Text>
                                <Text style={{fontSize: 12, color: 'gray'}}>Festivals</Text>
                            </View>
                        </View>

                        <Feather name="chevron-right" size={20} color="black" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> navigation.navigate('Gaming')} style={{height: 60, width: '100%', backgroundColor: 'transparent', justifyContent: 'space-between', borderRadius: 10, borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Entypo name="game-controller" size={18}/>
                            <View style={{marginLeft: 20}}>
                                <Text style={{fontWeight: 'bold'}}>E-Gaming</Text>
                                <Text style={{fontSize: 12, color: 'gray'}}>Festivals</Text>
                            </View>
                        </View>

                        <Feather name="chevron-right" size={20} color="black" />
                    </TouchableOpacity>
                </View>
                
            </View>

            </ScrollView>
           
        </View>
    )
}

export default HomeScreen