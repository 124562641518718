import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity, ScrollView, ActivityIndicator} from 'react-native';
import { useNavigation, useRoute } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { FlashList } from "@shopify/flash-list";

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getCampaign, getEvent, getUserData } from "../graphql/queries";

const {width, height} = Dimensions.get('screen')

const ApprovalsDetails = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const [event, setEvent] = useState();
    const [campaign, setCampaign] = useState();
    const [organiser, setOrganiser] = useState();

    const [TotalAmount, setTotalAmount] = useState();
    const [soldTickets, setSoldTickets] = useState([]);

    const [spinning, setSpinning] = useState(false)

 

    const listingEvents = async() => {
      
        try{
            await API.graphql(graphqlOperation(getEvent, { id: route?.item.id})).then((ef)=> {
            const userdd = ef.data.getEvent
  
            setEvent(userdd)
          })
        } catch (e){
            console.error(e)
          }    
       

        try{
            await API.graphql(graphqlOperation(getCampaign, { id: route?.item.campaignId})).then((ef)=> {
            const userd = ef.data.getCampaign
  
            setCampaign(userd)
          })

          
    
      } catch (e){
        console.error(e)
      }    

      try{
        await API.graphql(graphqlOperation(getUserData, { id: route?.item.ownerId})).then((ef)=> {
        const userda = ef.data.getUserData

        setOrganiser(userda)
      })} catch (e){
        console.error(e)
      }   

      try{
        const allTodos = await API.graphql({query: queries.listOrders, variables: 
            {
            filter : { and: [ 
                {eventId: {eq: route?.item.id}}             
            ]}
        } });
        
        const todos = allTodos.data.listOrders.items

        setSoldTickets(todos)
        
            
        let totalPrice = 0;

          totalPrice = todos?.reduce((summedPrice, product) => 
          (summedPrice + (product?.orderAmount || 0) * 1 ), 
         0);

         setTotalAmount(totalPrice)

      } catch (e){
        console.error(e)
      }   



    }



useEffect(() => {

    listingEvents();
  }, [event])

  
  const approveEvent = async() => {
   setSpinning(true)

    const inputM = {
        id: route?.item.id,
        startStatus: 'RequestedApproved'
    }

    const inputUser = {
        id: organiser?.id,
        balance: (organiser?.balance || 0) + TotalAmount,
        pendingBalance: (organiser?.pendingBalance || 0) - TotalAmount,

    }

    try {
        await API.graphql({ query: mutations.updateEvent, variables: {input: inputM}}).then((ess)=> {

            API.graphql({ query: mutations.updateUserData, variables: {input: inputUser}}).then((eff)=> {

                setSpinning(false),
            navigation.goBack()
            })

            
        })

    } catch (error) {
        console.error(error),
        setSpinning(false)
        return
    }

  }


  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
      <View style={{height: 60, width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Approval Details</Text> 
                </Animated.View>
                
        </View>

        <ScrollView style={{backgroundColor: '#fcfcfc', height: height - 60 - 80, marginTop: 5}}>
            <ImageBackground style={{height: width / 2, width: width}}
                source={{uri: campaign?.image}}
            >
                <View style={{height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.6)', alignItems: 'center', justifyContent: 'center'}}>
                    <View style={{height: 80, width: 80, borderWidth: 3, borderColor: '#fcfcfc'}}>
                        <Image  source={{uri: campaign?.image}} style={{height: '100%', width: '100%'}}/>
                    </View>
                    <Text style={{fontWeight: 'bold', color: '#fcfcfc', fontSize: 12, marginTop: 5}}>{event?.name}</Text>
                </View>

            </ImageBackground>
            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

            <View style={{padding: 15}}>
                <Text>Event Details</Text>


                <View style={{paddingTop: 15}}>

                        
                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='calendar' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Date</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{event?.eventDate}</Text>
                    </View>

                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='calendar' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Time</Text>
                        </View>
                        <Text style={{fontSize: 12,}}>{event?.eventTime}</Text>
                    </View>
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='map' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>City</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{event?.city}</Text>
                    </View>

                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='map' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Country</Text>
                        </View>
                        <Text style={{fontSize: 12,}}>{event?.country}</Text>
                    </View>
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '25%'}}>
                            <Feather    name='map-pin' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Address</Text>
                        </View>
                        
                        <Text style={{fontSize: 12, height: 30}}>{String(event?.address)}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '25%'}}>
                            <Feather    name= 'info' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Desc.</Text>
                        </View>
                        
                        <Text style={{fontSize: 12, height: 100, width: width / 1.5, alignItems: 'center', textAlign: 'justify'}}>{String(event?.description)}</Text>
                    </View>

                    
                    
                </View>

                

               

                </View>
            </View>    

            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

            <View style={{padding: 15}}>
                <Text>Campaign Details</Text>


                <View style={{paddingTop: 15}}>

                        
                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='calendar' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Min</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>ZAR{Number(campaign?.minAmount).toFixed(2)}</Text>
                    </View>

                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='map' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Country</Text>
                        </View>
                        <Text style={{fontSize: 12,}}>{campaign?.country}</Text>
                    </View>
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='info' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Genre</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{campaign?.genre}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '25%'}}>
                            <Feather    name='map-pin' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Campaign</Text>
                        </View>
                        
                        <Text style={{fontSize: 12, height: 30}}>{String(campaign?.name)}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '25%'}}>
                            <Feather    name= 'info' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Desc.</Text>
                        </View>
                        
                        <Text style={{fontSize: 12, height: 100, width: width / 1.5, alignItems: 'center', textAlign: 'justify'}}>{String(campaign?.description)}</Text>
                    </View>

                    
                    
                </View>

                

               

                </View>
            </View>    
            
            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

            <View style={{padding: 15}}>
                <Text>Organiser Details</Text>


                <View style={{paddingTop: 15}}>

                        
                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='calendar' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Name</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.firstName}</Text>
                    </View>

                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='map' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Surname</Text>
                        </View>
                        <Text style={{fontSize: 12,}}>{organiser?.surname ||`--`}</Text>
                    </View>
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='mail' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Email</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.email}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name= 'phone' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Phone</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.phoneNumber}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Balance</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>ZAR{Number(organiser?.balance || 0).toFixed(2)}</Text>
                    </View>


                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Pending</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>ZAR{Number(organiser?.pendingBalance || 0).toFixed(2)}</Text>
                    </View>

                    
                    
                </View>


                </View>
            </View>  

            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

            <View style={{padding: 15}}>
                <Text>Sales Details</Text>


                <View style={{paddingTop: 15}}>
                <Text style={{alignSelf: 'center', fontSize: 20, fontWeight: 'bold'}}>ZAR{Number(TotalAmount||0).toFixed(2)}</Text>
                <Text style={{alignSelf: 'center', fontSize: 11, color: 'gray'}}>Sales Amount</Text>

                <View style={{marginTop: 15}}>
                <Text style={{alignSelf: 'center', fontSize: 18, fontWeight: 'bold'}}>{soldTickets?.length}</Text>
                <Text style={{alignSelf: 'center', fontSize: 11, color: 'gray'}}>Total Sales</Text>
                </View>
                              

              

                </View>
            </View> 

            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

            <View style={{alignItems: 'center', marginBottom: 25}}>
                <TouchableOpacity style={{height: 45, alignItems: 'center', justifyContent: 'center', width: '80%', backgroundColor: 'green', borderRadius: 5, marginTop: 20}}
                onPress={()=> approveEvent()}
                >
                    {spinning && 
                    <View style={{position: 'absolute', left: 10}}>
                        <ActivityIndicator  size={'small'} color={'#fcfcfc'} />
                    </View>
                    }
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Approve</Text>
                </TouchableOpacity>

                <TouchableOpacity 
                onPress={()=> navigation.goBack()}
                style={{height: 45, alignItems: 'center', justifyContent: 'center', width: '80%', backgroundColor: 'red', borderRadius: 5, marginTop: 10, marginBottom: 20}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Reject</Text>
                </TouchableOpacity>
            </View>
           
        </ScrollView>

        

    </View>
  )
}

export default ApprovalsDetails

