import { StyleSheet, Text, View, Image, Animated, ImageBackground, TextInput, TouchableOpacity, Dimensions, ScrollView } from 'react-native'
import React, {useRef, useState, useEffect} from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
// import { LinearGradient } from 'expo-linear-gradient';
import Tabs from './Tabs';
// import ShopTabs from './ShopTabs';
// import LottieView from 'lottie-react-native';


const {width, height} = Dimensions.get('screen')

const TabNav = () => {



 const [userInfo, setUserInfo] = useState()

    const [emailaddress, changeEmailAddress] = useState('');
    const [emailPassword, changeEmailPassword] = useState('');


    const profileImage = 'https://images.unsplash.com/photo-1531727991582-cfd25ce79613?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'

    
  
 const mainColour = '#d40404';
 const toyinColour = '#f25268';
 

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}> 


        <Animated.View style={{flex: 1, position: 'absolute', top: 0, width: width, height: height}}>
            



        <View style={{height: '11%', paddingLeft: 10, paddingRight: 20, paddingBottom: 5,  flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
           <Animated.View style={{flexDirection: 'row', alignItems: 'center'}}>
            <TouchableOpacity style={{height: 45, width: 45, borderWidth: 0.5, borderColor: '#04070f', borderRadius: 50, alignItems: 'center', justifyContent: 'center'}}>
                <Image style={{height: 40, width: 40, borderRadius: 50,}} source={{uri: profileImage}}/>
            </TouchableOpacity>
            <Text style={{marginLeft: 10, fontWeight: 'bold', fontSize: 13}}>Hey {`Admin `}</Text>
            </Animated.View>
            
           
 
            {/*     
            <Animated.View style={{transform: [{
                translateX: menuAnim
            }]}}>
            <TouchableOpacity onPress={()=> {}} style={{marginBottom: 10}}>
                <AntDesign name="appstore-o" size={20} color="black" />
            </TouchableOpacity>
            </Animated.View> */}
        </View>
        <View style={{flex: 1,}}>
        <NavigationContainer>
            <Tabs/>
        </NavigationContainer>
        </View>
        </Animated.View>

        {/* <Animated.View style={{position: 'absolute', marginTop: -1, opacity: LogBackOpac, zIndex: 4000, height: height, width: width, backgroundColor: 'rgba(0,0,0, 0.8)', transform:[{
            translateY: LoginBackdrop
        }]}} /> */}



    </View>
    
  )
}

export default TabNav

const styles = StyleSheet.create({})