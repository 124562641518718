import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity, TextInput, ActivityIndicator} from 'react-native';
import { useNavigation, useRoute } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { FlashList } from "@shopify/flash-list";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const {width, height} = Dimensions.get('screen')

const ChatConv = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const [events, setEvents] = useState([]);
    const [spinning, setSpinning] = useState(false)
    const [message, setMessage] = useState('');

    const listingEvents = async() => {
      

        const allTodos = await API.graphql({query: queries.listChats, variables: 
            {
            filter : { and: [ 
                {ChatRoom: {eq: route?.roomId}}             
            ]}
        } });
        
        const todos = allTodos.data.listChats.items
        setEvents(todos)


    }


    events.sort(function(a, b) {

        const newerDate = String(a.updatedAt).replace(/\D/g, "")
        const newerB = String(b.updatedAt).replace(/\D/g, "")
      
        return parseFloat(newerB) - parseFloat(newerDate);
        });


        


    const renderEvents = ({item})=> {
        if(!item?.userId){
            return(
              <View style={{width: width - 30, paddingVertical: 10, paddingHorizontal: 15, marginRight: 0}}>
                
              <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#a4c1db', borderRadius: 15, position: 'relative', right: 0, borderTopRightRadius: 0}}>
                
                
    
                <View style={{marginLeft: 10, padding: 15}}>
      
                  <View style={{}}>
                    <Text style={{fontSize: 12}}>{item.message}</Text>
                  </View>
                  <Text style={{fontSize: 9, color: 'gray'}}>{String(item.createdAt).slice(0,10)}</Text>
                </View>
               
              
              </View>
              
              </View>
            )
          } else {
            return(
              <View style={{width: width - 30, paddingVertical: 10, paddingHorizontal: 15, marginRight: 0,}}>
                
              <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#a4dbb1', borderRadius: 15, position: 'relative', right: 0, borderTopLeftRadius: 0}}>
                
                
    
                <View style={{marginLeft: 10, padding: 15}}>
      
                  <View style={{}}>
                    <Text style={{fontSize: 12}}>{item.message}</Text>
      
                  </View>
                  <Text style={{fontSize: 9, color: 'gray'}}>{String(item.createdAt).slice(0,10)}</Text>
                </View>
               
              
              </View>
              
              </View>
            )
          }
    }


    const itemSeperator = () => {
        return(
            <View style={{height: 5, backgroundColor: '#e3eefa', opacity: 0.3}} />
        )
    }

useEffect(() => {

    listingEvents();
  }, [events])

    events.sort(function(a, b) {

    const newerDate = String(a.createdAt).replace(/\D/g, "")
    const newerB = String(b.createdAt).replace(/\D/g, "")

return parseFloat(newerB) - parseFloat(newerDate);
    });


    const newMessage = async() => {

        if(!message){
          return
        }
  
        setSpinning(true)
  
  
        const newChatInput = {
          ChatRoom: route?.roomId,
          message: message,
          read: false,
        }
  
          try {
            
            await API.graphql(graphqlOperation(mutations.createChats, { input: newChatInput})).then((e)=> {
                  setMessage(''),
                  setSpinning(false)        
            })
  
  
          } catch (error) {
            console.error(error),
            setSpinning(false)
          }
  
  
      }







  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
      <View style={{height: 60, width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>{route?.subject}</Text>
                </Animated.View>
                
        </View>

        <View style={{backgroundColor: '#fcfcfc', height: height / 1.8, marginTop: 5, paddingLeft: 15}}>
            <FlashList 
                data={events}
                estimatedItemSize={100}
                renderItem={renderEvents}
            />
        </View>

        <View style={{height: 80, width: width - 0, backgroundColor: '#fcfcfc', bottom: 15, alignSelf: 'center', borderWidth: 1, borderColor: '#e3eefa', flexDirection: 'row'}}>
            <TextInput style={{height: '100%', width: '70%', paddingLeft: 15, alignItems: 'center'}} 
            placeholder="Type message here.."  multiline={true} value={message} onChangeText={setMessage} />



{spinning ? 
                  
                <TouchableOpacity onPress={()=> newMessage()} style={{width: 40, height: 40, backgroundColor: 'red', borderRadius: 40, alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 15, top: 20}}>
                    <ActivityIndicator size={'small'} color={'white'} />  
                </TouchableOpacity>
                  
                  
                  
                  :
                  <TouchableOpacity onPress={()=> newMessage()} style={{width: 40, height: 40, backgroundColor: 'red', borderRadius: 40, alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 15, top: 20}}>
                    <Feather name='send' color={'#fcfcfc'} size={20} />
                </TouchableOpacity>
              }



            
        </View>



    </View>
  )
}

export default ChatConv

