import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, {useState, useEffect} from 'react';
import { View } from "react-native";
import HomeScreen from "../Screens/HomeScreen";
import Campaigns from "../Screens/Campaigns";
import AddNewEvent from "../Screens/NewCampaign";
import NewCampaign from "../Screens/NewCampaign";
import ManageCampaign from "../Screens/ManageCampaign";
import EventQueries from "../Screens/EventQueries";
import EventScreen from "../Screens/EventScreen";
import ManageEvent from "../Screens/ManageEvents";
import CampaignsB from "../Screens/Campaigns/CampaignsB";
import PendingDetails from "../Screens/Campaigns/PendingDetails";

const Stack = createNativeStackNavigator();

const CampaignsStack = () => {

    return (
      <View style={{flex: 1}}>
        <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
            >
             <Stack.Screen 
                
                name="Campaigns">
                    {() => <CampaignsB />}
                </Stack.Screen>   
                <Stack.Screen component={NewCampaign} name="NewCampaign"/>  
                <Stack.Screen component={ManageCampaign} name="ManageCampaign"/> 
                <Stack.Screen component={PendingDetails} name="PendingDetails"/> 
                <Stack.Screen component={EventScreen} name="EventScreen"/>
                <Stack.Screen component={ManageEvent} name="ManageEvent"/>
        </Stack.Navigator>
      </View>

    )
}
export default CampaignsStack;
