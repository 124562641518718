import { View, Text, TouchableOpacity, ImageBackground, Image, ScrollView, Dimensions, Pressable, FlatList } from 'react-native'
import React, {useState, useEffect} from 'react';
const {width, height} = Dimensions.get('screen');
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'; 

import { useNavigation, useRoute } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import * as queries from '../graphql/queries';
import { API, graphqlOperation, Auth } from 'aws-amplify';

const ManageGame = () => {

    const backColour = '#e3eefa';
    const ourOrange = '#FF4F00';

    const navigation = useNavigation();
    const route = useRoute().params

    const [opt, setOpt] = useState('Players');
    const [mainOpt, setMainOpt] = useState(false);
    const [secOpt, setSecOpt] = useState('');

    const [events, setEvents] = useState([]);

    const gamer = 'https://images.pexels.com/photos/6498731/pexels-photo-6498731.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'

  return (
    <View style={{flex: 1, backgroundColor: backColour}}>
      <View style={{width: width, height: height - 80,}}>
            <ImageBackground style={{height: height/6, width: '100%'}}
                    source={{uri: route?.item.image}}
                    >
                        <View style={{position: 'absolute', top: 15, left: 15, borderRadius: 50, zIndex: 5000}}>
                            <TouchableOpacity 
                            onPress={()=> navigation.goBack()}
                            style={{height: 50, width: 50, borderRadius: 50}}>
                                <Feather name='arrow-left' size={20} color={'white'} />
                            </TouchableOpacity>
                        </View>

                        <View style={{height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.6)', alignItems: 'flex-end', padding: 40}}>
                            <View style={{alignItems: 'center'}}>
                                <Text style={{fontWeight: 'bold', color: '#fcfcfc', fontSize: 14}}>{route?.item.name}</Text>
                                <Text style={{fontWeight: 'bold', color: ourOrange, fontSize: 14,}}>{route?.item.date}</Text>
                            </View>
                        </View>

            </ImageBackground>

            <View style={{margin: 15, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10}}>
                                <Text style={{fontSize: 18, fontWeight: 'bold', marginBottom: 10}}>Manage Event</Text>

                                <View style={{height: 40, width: '100%', alignItems: 'center', paddingHorizontal: 3, justifyContent: 'space-between', backgroundColor: backColour, flexDirection: 'row', borderRadius: 10}}>
                                    <TouchableOpacity 
                                    onPress={()=> setOpt('Players')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Players'  ? '#fcfcfc' : 'transparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>PLAYERS</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity 
                                    onPress={()=> setOpt('Options')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Options'  ? '#fcfcfc' : 'transparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>OPTIONS</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity 
                                    onPress={()=> setOpt('Matches')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Matches'  ? '#fcfcfc' : 'transparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>MATCHES</Text>
                                    </TouchableOpacity>
                                </View>
            </View>

            <View style={{marginHorizontal: 10, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10}}>
            {
                opt === 'Players' && 
                <View>

                    {/* <FlatList
                        data={events}
                        renderItem={renderEvents}
                        style={{height: height / 3+15}}
                    /> */}


                </View>
            }

            {
                opt === 'Options' && 
                <View>
                    <Pressable 
                    onPress={()=> {setMainOpt(true), setSecOpt('Wallet')}}
                    style={{height: 60, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <Ionicons name= 'md-wallet-outline' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 14, fontWeight: 'bold', opacity: 0.6}}>Orders</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Income : R0.00</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>

                    <Pressable 
                    onPress={()=> {navigation.navigate('GamingHub')}}
                    style={{height: 60, marginTop: 10, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'controller-classic-outline' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 14, fontWeight: 'bold', opacity: 0.6}}>Suspend Event</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Permanent</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>




                </View>
            }

{
                opt === 'Matches' && 
                <View>
                    <Pressable 
                    onPress={()=> {setMainOpt(true), setSecOpt('Wallet')}}
                    style={{height: 60, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <Ionicons name= 'md-wallet-outline' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 14, fontWeight: 'bold', opacity: 0.6}}>Create Match</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>New match - two players</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>

                   <View style={{marginTop: 10}}>
                    <FlatList 
                        data={events}
                    />
                   </View>

                </View>
            }



            </View>
           


        </View>
    </View>
  )
}

export default ManageGame