import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity} from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';



const {width, height} = Dimensions.get('screen')


const FinancesM = () => {

    const navigation = useNavigation()

    const theorange = '#d14324';
    const theblue = '#226dbf';

    const [transactions, setTransactions] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [pendingBalace, setPendingBalance] = useState(0)

    
 
    const WalletMainY = useRef(new Animated.Value(100)).current;  
    const eventsMainY = useRef(new Animated.Value(60)).current;   



    useEffect(() => {

      // checkAuth()

      Animated.parallel([
          Animated.timing(WalletMainY, {
              toValue: 0,
              useNativeDriver: true
          }),
          Animated.timing(eventsMainY, {
              toValue: 0,
              useNativeDriver: true
          }),
      ]).start()
  
    }, [userInfo]);

  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
      <Animated.View style={{alignItems: 'center',marginTop: 10,  backgroundColor: '#fcfcfc',  borderRadius: 10, paddingTop: 25, paddingBottom: 25, margin: 10, transform: [{translateY: WalletMainY}]}}>
                
                <View style={{position: 'absolute', height: 30, width: 30, borderRadius: 40, backgroundColor: theorange, top: 5, right: 5, alignItems: 'center', justifyContent: 'center'}}>
                   <TouchableOpacity onPress={()=> console.warn('ss')}>
                    <Entypo name= 'wallet' size={12} color="#fcfcfc" />
                   </TouchableOpacity>
                    
                </View>


                <Animated.View style={{flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'}}>
                     <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number(userInfo?.balance || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>available balance</Text>
                    </Animated.View>

                    <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number(userInfo?.pendingBalance || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>pending balance</Text>
                    </Animated.View>               
                </Animated.View>

                <Animated.View style={{width: '80%', height: 70, backgroundColor: 'rgba(227, 238, 250, 0.5)', flexDirection: 'row', borderRadius: 10, marginTop: 15 }}>
                    
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
                        <TouchableOpacity style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>
                                
                                <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                                    <Feather name='list' color='#fcfcfc' size={15} />
                                </View>

                                <Text style={{fontSize: 10,  marginTop: 5}}>History</Text>

                        </TouchableOpacity>

                        <TouchableOpacity 
                        onPress={()=> navigation.navigate('CashOuts')}
                        style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>


                                
                            <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                                <Feather name= 'arrow-down' color='#fcfcfc' size={15} />
                            </View>

                            <Text style={{fontSize: 10,  marginTop: 5}}>Cashouts</Text>

                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=> navigation.navigate('Approvals')} style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>


                        <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                            <Feather name= 'arrow-up' color='#fcfcfc' size={15} />
                        </View>

                            <Text style={{fontSize: 10,  marginTop: 5}}>Approvals</Text>

                    </TouchableOpacity>

                    </View>

                    


                </Animated.View>

               

            </Animated.View>
    </View>
  )
}

export default FinancesM