import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, {useState, useEffect} from 'react';
import { View } from "react-native";
import HomeScreen from "../Screens/HomeScreen";
import Campaigns from "../Screens/Campaigns";
import AddNewEvent from "../Screens/NewCampaign";
import NewCampaign from "../Screens/NewCampaign";
import ManageCampaign from "../Screens/ManageCampaign";
import EventQueries from "../Screens/EventQueries";
import EventScreen from "../Screens/EventScreen";
import ManageEvent from "../Screens/ManageEvents";
import Banners from "../Screens/Banners";
import AddBanners from "../Screens/AddBanners";
import Aone from "../Screens/Aone";
import AddSecOne from "../Screens/AddSecOne";
import Atwo from "../Screens/Atwo";
import AddSecTwo from "../Screens/AddSecTwo";
import Athree from "../Screens/Athree";
import AddSecThree from "../Screens/AddSecThree";
import Egaming from "../Screens/Egaming";
import NewEgame from "../Screens/NewEgame";
import ManageGame from "../Screens/ManageGame";

const Stack = createNativeStackNavigator();

const HomeStack = () => {

    return (
      <View style={{flex: 1}}>
        <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
            >
             <Stack.Screen 
                
                name="HomeScreen">
                    {() => <HomeScreen />}
                </Stack.Screen>   
                <Stack.Screen component={Campaigns} name="Campaigns"/>
                <Stack.Screen component={NewCampaign} name="NewCampaign"/>  
                <Stack.Screen component={ManageCampaign} name="ManageCampaign"/> 
                <Stack.Screen component={EventQueries} name="EventQueries"/> 
                <Stack.Screen component={EventScreen} name="EventScreen"/>
                <Stack.Screen component={ManageEvent} name="ManageEvent"/>
                <Stack.Screen component={Banners} name="Banners"/>
                <Stack.Screen component={AddBanners} name="AddBanners"/>
                <Stack.Screen component={Aone} name="Aone"/>
                <Stack.Screen component={AddSecOne} name="AddSecOne"/>
                <Stack.Screen component={Atwo} name="Atwo"/>
                <Stack.Screen component={AddSecTwo} name="AddSecTwo"/>
                <Stack.Screen component={Athree} name="Athree"/>
                <Stack.Screen component={AddSecThree} name="AddSecThree"/>
                <Stack.Screen component={Egaming} name="Gaming"/>
                <Stack.Screen component={NewEgame} name="NewEgame"/>
                <Stack.Screen component={ManageGame} name="ManageGame"/>
        </Stack.Navigator>
      </View>

    )
}
export default HomeStack;
