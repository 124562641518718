import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity} from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import * as queries from '../graphql/queries';
import { FlashList } from "@shopify/flash-list";

const {width, height} = Dimensions.get('screen')


const Chats = () => {

    const navigation = useNavigation()

    const theorange = '#d14324';
    const theblue = '#226dbf';

    const [userInfo, setUserInfo] = useState();
    const [chats, setChats] = useState([])
    
 
    const WalletMainY = useRef(new Animated.Value(100)).current;  
    const eventsMainY = useRef(new Animated.Value(60)).current;   


    const fetchingChatRooms = async() => {

  

        try {
            const todoData = await API.graphql({query: queries.listChatRooms})
              const todos = todoData.data.listChatRooms.items
              setChats(todos)
    
         
        } catch (error) {
            console.error(error)
        }

       
    }



    useEffect(() => {

      fetchingChatRooms()

      Animated.parallel([
          Animated.timing(WalletMainY, {
              toValue: 0,
              useNativeDriver: true
          }),
          Animated.timing(eventsMainY, {
              toValue: 0,
              useNativeDriver: true
          }),
      ]).start()
  
    }, [chats]);


    chats.sort(function(a, b) {

        const newerDate = String(a.updatedAt).replace(/\D/g, "")
        const newerB = String(b.updatedAt).replace(/\D/g, "")
      
        return parseFloat(newerB) - parseFloat(newerDate);
        });

    const renderChatRooms  = ({item}) => {
        return(
            <TouchableOpacity 
                onPress={()=> navigation.navigate('ChatConv', {subject: item?.subject, roomId: item?.id})}
                style={{height: 80, width: width - 15, backgroundColor: '#fcfcfc', alignSelf: 'center', marginTop: 10, borderRadius: 10, flexDirection: 'row', paddingLeft: 5}}>
                <View style={{width: 70, height: 70, borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}> 
                    <Image 
                        source={{uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8NRCvuIoaBbVxIO0j1A5aNecc04Sf4ekBM1n3HPb7&s'}}
                        style={{height: '90%', width: '90%', borderRadius: 10, marginTop: 10}}
                    />
                </View>
                <View style={{height: '100%', width: '60%', justifyContent: 'center', marginLeft: 10}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.8}}>{item.subject}</Text>
                    <Text style={{fontSize: 10, color: 'gray'}}>{String(item.createdAt).slice(0,10)}</Text>
                </View>

                    {!item.open && 
                        <View 
                            style={{height: 8, width: 8, backgroundColor: 'red', borderRadius: 10, position: 'absolute', right: 10, top: 40}}                        
                        />
                    }
                
            </TouchableOpacity>
        )
    }

    const itemSeperator = () => {
        return(
            <View style={{height: 5, width: width}} />
        )
    }



  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
        <View style={{height: 60, width: width, backgroundColor: '#fcfcfc', justifyContent: 'center', paddingLeft: 15}}>
            <Text style={{fontSize: 16, fontWeight: 'bold'}}>Chats</Text>
        </View>


        <Animated.View style={{height: height - 60 - 90, width: width,}}>
            <FlashList 
                data={chats}
                renderItem={renderChatRooms}
                estimatedItemSize={100}
                ItemSeparatorComponent={itemSeperator}
            />
        </Animated.View>
       



      <Animated.View style={{alignItems: 'center',marginTop: 10,  backgroundColor: '#fcfcfc',  borderRadius: 10, paddingTop: 25, paddingBottom: 25, margin: 10, transform: [{translateY: WalletMainY}]}}>
                
                <View style={{position: 'absolute', height: 30, width: 30, borderRadius: 40, backgroundColor: theorange, top: 5, right: 5, alignItems: 'center', justifyContent: 'center'}}>
                   <TouchableOpacity onPress={()=> console.warn('ss')}>
                    <Entypo name= 'wallet' size={12} color="#fcfcfc" />
                   </TouchableOpacity>
                    
                </View>


                <Animated.View style={{flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'}}>
                     <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number(userInfo?.balance || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>available balance</Text>
                    </Animated.View>

                    <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number(userInfo?.pendingBalance || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>pending balance</Text>
                    </Animated.View>               
                </Animated.View>

                <Animated.View style={{width: '80%', height: 70, backgroundColor: 'rgba(227, 238, 250, 0.5)', flexDirection: 'row', borderRadius: 10, marginTop: 15 }}>
                    
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
                        <TouchableOpacity style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>
                                
                                <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                                    <Feather name='list' color='#fcfcfc' size={15} />
                                </View>

                                <Text style={{fontSize: 10,  marginTop: 5}}>History</Text>

                        </TouchableOpacity>

                        <TouchableOpacity 
                        onPress={()=> navigation.navigate('CashOuts')}
                        style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>


                                
                            <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                                <Feather name= 'arrow-down' color='#fcfcfc' size={15} />
                            </View>

                            <Text style={{fontSize: 10,  marginTop: 5}}>Cashouts</Text>

                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=> navigation.navigate('Approvals')} style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>


                        <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                            <Feather name= 'arrow-up' color='#fcfcfc' size={15} />
                        </View>

                            <Text style={{fontSize: 10,  marginTop: 5}}>Approvals</Text>

                    </TouchableOpacity>

                    </View>

                    


                </Animated.View>

               

            </Animated.View>
    </View>
  )
}

export default Chats