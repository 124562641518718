import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Animated, Image, ActivityIndicator } from 'react-native'
import React, {useState, useEffect, useRef} from 'react';
import { useNavigation } from '@react-navigation/native';
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
const {width, height} = Dimensions.get('window');
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { FlashList } from '@shopify/flash-list';
import { Auth, API, graphqlOperation } from 'aws-amplify';

const AddBanners = () => {

    const navigation = useNavigation();

    const [banners, setBanners] = useState([[]])
    const [spinning, setSpinning] = useState(false)

    const mainColour = '#d40404';
    const deepBlueColour = '#052242';
    const theblue = '#226dbf';

    const listingBanners = async() => {
      

            const allTodos = await API.graphql({query: queries.listCampaigns, variables: 
                {
                filter : { and: [ 
                    {active: {eq: true}},
                    {approved: {eq: true}},
                    {banner: {ne: true}},
                    
                ]}
            } });
            
            const todos = allTodos.data.listCampaigns.items
            setBanners(todos)


    }

    useEffect(() => {

        listingBanners();
      }, [banners])


      const renderCampaigns = ({item}) => {


        const addBannerInput = {
            id: item.id,
            banner: true
        }

            const addToBanner = async()=> {

                setSpinning(true)

                    try {
                        await API.graphql({query: mutations.updateCampaign, variables: {input: addBannerInput}}).then((ee)=> {
                            setSpinning(false)
                            navigation.goBack()
                          }) 
                    } catch (error) {
                        console.error(error),
                        setSpinning(false)
                    }


            }
        
           
        


        return(
            <TouchableOpacity onPress={()=> addToBanner()} style={{height: 80, width: width - 30, marginBottom: 10, borderWidth: 0, borderColor: '#d1d1d1', alignSelf: 'center', borderRadius: 10, paddingLeft: 5, flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
                
                <View style={{width: '20%'}}>
                    <Image
                        style={{height: 70, width: 70, borderRadius: 10}}
                        source={{uri: item?.image}}
                    />

                    {spinning && 
                        <View style={{height: 70, width: 70, alignItems: 'center', justifyContent: 'center', position: 'absolute', borderRadius: 10,  backgroundColor: 'rgba(0,0,0,0.6)'}}>
                            <ActivityIndicator color={'white'} size={'small'} />
                        </View>
                    }
                    
                    



                </View>

                <View style={{height: '100%', marginLeft: 15, justifyContent: 'center', width: '65%'}}>
                  <Text style={{fontWeight: 'bold', fontSize: 12, color: deepBlueColour}}>{item.name}</Text>  
                  <Text style={{fontSize: 10, width: width / 2, textAlign: 'justify', color: 'gray'}}>{String(item?.description).slice(0, 50)}...</Text>
                </View>

                <View style={{width: 30, height: 30, justifyContent: 'center', alignItems: 'center', paddingRight: 0, borderRadius: 50, backgroundColor: 'rgba(7, 31, 247, 0.5)' }}>
                    <Feather name= 'plus' size={15} color={'white'}/>
                </View>
                
            </TouchableOpacity>
        )
      }


  return (
    <View style={{flex: 1, backgroundColor: '#e8e8e8'}}>
        <View style={{height: 60, width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Banner</Text>
                </Animated.View>
                
        </View>

       
        <View style={{width: '100%', height: '100%', paddingTop: 15, backgroundColor: '#fcfcfc', alignSelf: 'center'}}>

                    <FlashList 
                        data={banners}
                        renderItem={renderCampaigns}
                        estimatedItemSize={3000}
                    />
                    </View>






    </View>
  )
}

export default AddBanners

const styles = StyleSheet.create({})