import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity, ScrollView, ActivityIndicator} from 'react-native';
import { useNavigation, useRoute } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { FlashList } from "@shopify/flash-list";

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getCampaign, getEvent, getUserData } from "../graphql/queries";

const {width, height} = Dimensions.get('screen')

const CashOutDetails = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const [event, setEvent] = useState();
    const [campaign, setCampaign] = useState();
    const [organiser, setOrganiser] = useState();

    const [TotalAmount, setTotalAmount] = useState();
    const [soldTickets, setSoldTickets] = useState([]);

    const [spinning, setSpinning] = useState(false)

 

    const listingEvents = async() => {
      
      
      try{
        await API.graphql(graphqlOperation(getUserData, { id: route?.item.id})).then((ef)=> {
        const userda = ef.data.getUserData

        setOrganiser(userda)
      })} catch (e){
        console.error(e)
      }   

    



    }



useEffect(() => {

    listingEvents();
  }, [event])

  
  const approveCashout = async() => {
   setSpinning(true)

   
    const inputUser = {
        id: organiser?.id,
        withdrawnAmount: 0
    }

    try {
            await API.graphql({ query: mutations.updateUserData, variables: {input: inputUser}}).then((eff)=> {

                setSpinning(false),
            navigation.goBack()
            })
    } catch (error) {
        console.error(error),
        setSpinning(false)
        return
    }

  }


  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
      <View style={{height: 60, width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Approval Details</Text> 
                </Animated.View>
                
        </View>

        <ScrollView style={{backgroundColor: '#fcfcfc', height: height - 60 - 80, marginTop: 5}}>
           
            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

           

            
            <View style={{padding: 15}}>
                <Text>Organiser Details</Text>


                <View style={{paddingTop: 15}}>

                        
                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='calendar' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Name</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.firstName}</Text>
                    </View>

                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='map' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Surname</Text>
                        </View>
                        <Text style={{fontSize: 12,}}>{organiser?.surname ||`--`}</Text>
                    </View>
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='mail' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Email</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.email}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name= 'phone' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Phone</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.phoneNumber}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Balance</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>ZAR{Number(organiser?.balance || 0).toFixed(2)}</Text>
                    </View>


                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Pending</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>ZAR{Number(organiser?.pendingBalance || 0).toFixed(2)}</Text>
                    </View>

                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Bank</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.bank}</Text>
                    </View>


                   
                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Branch</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{organiser?.branchNumber}</Text>
                    </View>

                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>No.</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{(organiser?.accountNumber || 0)}</Text>
                    </View>
                   
                    
                    
                </View>

                <View style={{flexDirection: 'row', width: '100%', paddingTop: 15}}>
                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>Country</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{(organiser?.bankCountry)}</Text>
                    </View>


                    <View style={{flexDirection: 'row', width: '50%', alignItems: 'center'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center', width: '40%'}}>
                            <Feather    name='dollar-sign' size={12}   style={{marginRight: 5}} />
                            <Text style={{fontSize: 12, fontWeight: 'bold'}}>SWIFT</Text>
                        </View>
                        
                        <Text style={{fontSize: 12}}>{(organiser?.SwiftNumber || `--`)}</Text>
                    </View>

                    
                    
                </View>
                



                </View>
            </View>  

            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

            <View style={{padding: 15}}>
                <Text>Cashout Details</Text>


                <View style={{paddingTop: 15}}>
                <Text style={{alignSelf: 'center', fontSize: 20, fontWeight: 'bold'}}>ZAR{Number(organiser?.withdrawnAmount||0).toFixed(2)}</Text>
                <Text style={{alignSelf: 'center', fontSize: 11, color: 'gray'}}>Requested Amount</Text>

                                           

              

                </View>
            </View> 

            <View style={{height: 5, backgroundColor: '#e3eefa', width: width}} />

            <View style={{alignItems: 'center', marginBottom: 25}}>
                <TouchableOpacity style={{height: 45, alignItems: 'center', justifyContent: 'center', width: '80%', backgroundColor: 'green', borderRadius: 5, marginTop: 20}}
                onPress={()=> approveCashout()}
                >
                    {spinning && 
                    <View style={{position: 'absolute', left: 10}}>
                        <ActivityIndicator  size={'small'} color={'#fcfcfc'} />
                    </View>
                    }
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Approve</Text>
                </TouchableOpacity>

                <TouchableOpacity 
                onPress={()=> navigation.goBack()}
                style={{height: 45, alignItems: 'center', justifyContent: 'center', width: '80%', backgroundColor: 'red', borderRadius: 5, marginTop: 10, marginBottom: 20}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Reject</Text>
                </TouchableOpacity>
            </View>
           
        </ScrollView>

        

    </View>
  )
}

export default CashOutDetails

