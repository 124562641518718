import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput, ActivityIndicator } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { useNavigation, useRoute } from "@react-navigation/native";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { API, Auth, graphqlOperation } from "aws-amplify";

const {width, height} = Dimensions.get('screen')

const PendingDetails = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const [spinning, setSpinning] = useState(false)
    const [spinningD, setSpinningD] = useState(false)



    const acceptOrder = async() => {
        setSpinning(true)

        const updateOrder = {
            id: route?.item.id,
            active: true,
            approved: true
        }

        try {
            await API.graphql(graphqlOperation(mutations.updateCampaign, { input: updateOrder})).then((ee)=> {
                navigation.goBack(),
                setSpinning(false)
              })
        } catch (error) {
            console.error(error)
        }


    }   

    const declineOrder = async() => {
        setSpinningD(true)

        const updateOrder = {
            id: route?.item.id,
            active: false,
            approved: false,
            declied: true
        }

        try {
            await API.graphql(graphqlOperation(mutations.updateCampaign, { input: updateOrder})).then((ee)=> {
                navigation.goBack(),
                setSpinningD(false)
              })
        } catch (error) {
            console.error(error)
        }


    }   




    return(
        <View style={{flex: 1, backgroundColor: '#e3eefa', paddingTop: 0}}>
            <View style={{height: '8%', width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderBottomWidth: 0, borderColor: '#d1d1d1'}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Campaign</Text>
                </Animated.View>

                
                
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>

            

            <ImageBackground source={{uri: route?.item.image}} style={{height: height / 3}}/>

            <Animated.View style={{width: width - 30, paddingBottom: 60, backgroundColor: '#fcfcfc', alignSelf: 'center', marginTop: 20, padding: 15}}>
                <Text style={{fontSize: 16, fontWeight: 'bold'}}>{route?.item.name || 'Title'}</Text>

                <Text style={{fontSize: 12, marginTop: 10}}>{route?.item.description}</Text>


                <Animated.View style={{alignSelf: 'center', marginTop: 50, width: '100%', alignItems: 'center'}}>
                    <TouchableOpacity onPress={()=> acceptOrder()} style={{height: 45, width: '70%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                        
                        <View style={{position: 'absolute', left: 15}}>
                            {spinning ? <ActivityIndicator color={'#fcfcfc'} size='small' /> : <View/>}
                        </View>
                        
                        
                        
                        <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Accept</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> declineOrder()} style={{height: 45, width: '70%', borderColor: 'green', borderWidth: 1, alignItems: 'center', justifyContent: 'center', borderRadius: 10, marginTop: 15}}>
                       
                    <View style={{position: 'absolute', left: 15}}>
                            {spinningD ? <ActivityIndicator color={'green'} size='small' /> : <View/>}
                        </View>
                       
                        <Text style={{color: 'green', fontWeight: 'bold'}}>Decline</Text>
                    </TouchableOpacity>
                </Animated.View>



            </Animated.View>

            </ScrollView>
        </View>
    )
}
export default PendingDetails 