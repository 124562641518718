import { View, Text, TouchableOpacity, ImageBackground, Image, ScrollView, Dimensions, Pressable, FlatList } from 'react-native'
import React, {useState, useEffect} from 'react';
const {width, height} = Dimensions.get('screen');
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'; 

import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import * as queries from '../graphql/queries';
import { API, graphqlOperation, Auth } from 'aws-amplify';

const Egaming = () => {


    const backColour = '#e3eefa';
    const ourOrange = '#FF4F00';

    const gamer = 'https://images.pexels.com/photos/6498731/pexels-photo-6498731.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    const navigation = useNavigation()

    const [opt, setOpt] = useState('Events');
    const [mainOpt, setMainOpt] = useState(false);
    const [secOpt, setSecOpt] = useState('');

    const [events, setEvents] = useState([]);

    // const events = [
    //     {
    //         id: 1,
    //         name: 'FIFA 23 All Teams',
    //         minAmount: 150,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '22 August 2023',
    //         time: '10:00',
    //         prize: 1000,
    //         numPlayers: 16,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },
    //     {
    //         id: 2,
    //         name: 'FIFA 22 England Teams',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },

    //     {
    //         id: 3,
    //         name: 'FIFA 22 ESP',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },
    //     {
    //         id: 4,
    //         name: 'FIFA 22 German',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },
    //     {
    //         id: 5,
    //         name: 'FIFA 23 ROTW',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },
    //     {
    //         id: 6,
    //         name: 'FIFA 22 UK',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },
    //     {
    //         id: 7,
    //         name: 'FIFA 22 UK',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },
    //     {
    //         id: 8,
    //         name: 'FIFA 22 ESP',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },
    //     {
    //         id: 9,
    //         name: 'FIFA 22 ROTW',
    //         minAmount: 120,
    //         image: 'https://sm.ign.com/ign_za/cover/f/fifa-23-le/fifa-23-legacy-edition_6c3y.jpg',
    //         date: '23 August 2023',
    //         time: '10:00',
    //         prize: 1500,
    //         numPlayers: 32,
    //         description: 'Are you a FIFA 23 master? Do you think you have what it takes to win R1000? Then sign up for the FIFA 23 eGaming Competition on the 22nd August 2023! The competition will be held online, and only 16 tickets are available. The entrance fee is R150. The competition will be single elimination, and the winner will take home R1000. The runner-up will receive R150'        
    //     },

    // ] 

    const renderEvents = ({item}) => {
        return(
            <Pressable 
                    onPress={()=> {navigation.navigate('ManageGame', {item: item})}}
                    style={{height: 60, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <Image source={{uri: item.image}} style={{height: 50, width: 50, borderRadius: 10}} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6}}>{item.name}</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>{item.date}</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>
        )
    }

    const listingEvents = async() => {
      

        const allTodos = await API.graphql({query: queries.listEgames, variables: 
            {
            filter : { and: [ 
                {genre: {eq: 'Egames'}},
                {active: {ne: false}},
                
            ]}
        } });
        
        const todos = allTodos.data.listEgames.items
        setEvents(todos)


    }

    useEffect(() => {
        listingEvents()
    }, [events])
    

  return (
    <View style={{flex: 1, backgroundColor: backColour}}>
        <View style={{width: width, height: height - 80,}}>
            <ImageBackground style={{height: height/6, width: '100%'}}
                    source={{uri: gamer}}
                    >
                        <View style={{position: 'absolute', top: 15, left: 15, borderRadius: 50, zIndex: 5000}}>
                            <TouchableOpacity 
                            onPress={()=> navigation.goBack()}
                            style={{height: 50, width: 50, borderRadius: 50}}>
                                <Feather name='arrow-left' size={20} color={'white'} />
                            </TouchableOpacity>
                        </View>

                        <View style={{height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.6)', alignItems: 'flex-end', padding: 40}}>
                            <View style={{alignItems: 'center'}}>
                                <Text style={{fontWeight: 'bold', color: '#fcfcfc', fontSize: 14}}>Gamer's win</Text>
                                <Text style={{fontWeight: 'bold', color: ourOrange, fontSize: 14,}}>Undisturbed</Text>
                            </View>
                        </View>

            </ImageBackground>

            <View style={{margin: 15, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10}}>
                                <Text style={{fontSize: 18, fontWeight: 'bold', marginBottom: 10}}>Favourites</Text>

                                <View style={{height: 40, width: '100%', alignItems: 'center', paddingHorizontal: 3, justifyContent: 'space-between', backgroundColor: backColour, flexDirection: 'row', borderRadius: 10}}>
                                    <TouchableOpacity 
                                    onPress={()=> setOpt('Events')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Events'  ? '#fcfcfc' : 'transparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>EVENTS</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity 
                                    onPress={()=> setOpt('Notifications')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Notifications'  ? '#fcfcfc' : 'transparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>NOTIFICATIONS</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity 
                                    onPress={()=> setOpt('Chat')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Chat'  ? '#fcfcfc' : 'transparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>CHAT SUPPORT</Text>
                                    </TouchableOpacity>
                                </View>
            </View>

            <View style={{marginHorizontal: 10, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10}}>
            {
                opt === 'Events' && 
                <View>

                    <FlatList
                        data={events}
                        renderItem={renderEvents}
                        style={{height: height / 3+15}}
                        // estimatedItemSize={100}
                    />


                </View>
            }

{
                opt === 'Notifications' && 
                <View>
                    <Pressable 
                    onPress={()=> {setMainOpt(true), setSecOpt('Wallet')}}
                    style={{height: 60, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <Ionicons name= 'md-wallet-outline' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>Wallet</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Balance : ZAR330.00</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>

                    <Pressable 
                    onPress={()=> {navigation.navigate('GamingHub')}}
                    style={{height: 60, marginTop: 10, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'controller-classic-outline' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>Gaming Hub</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Gaming tickets you bought</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>

                    <Pressable 
                    onPress={()=> {setMainOpt(true), setSecOpt('Profile')}}
                    style={{height: 60, marginTop: 10, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'face-man-profile' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>User Info</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Details about you</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>

                    <Pressable 
                    onPress={()=> {setMainOpt(true), setSecOpt('Profile')}}
                    style={{height: 60, marginTop: 10, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'gamepad' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>EA ID</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>What we will use to connect</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>

                    <Pressable 
                    onPress={()=> {setMainOpt(true), setSecOpt('Profile')}}
                    style={{height: 60, marginTop: 10, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'youtube-tv' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>Anydesk ID</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Screen sharing</Text>
                        </View>

                        <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                            <Entypo name= 'dots-three-horizontal' size={15} />
                        </Pressable>
                    </Pressable>


                </View>
            }



            </View>
           


        </View>

        <View style={{position: 'absolute', zIndex: 6000, right: 15, bottom: 50}}>
               
                {opt === 'Events' && 
                
                
               <TouchableOpacity 
               onPress={()=> navigation.navigate('NewEgame')}
               style={{flexDirection: 'row', height: 40, backgroundColor: ourOrange, alignItems: 'center', padding: 5, borderRadius: 10}}>
                    <Feather name='plus' color={'#fcfcfc'}  size={15} />
                    <Text style={{fontWeight: 'bold', color: '#fcfcfc', fontSize: 12, marginLeft: 5}}>New Event</Text>
                </TouchableOpacity>}
            </View>


    </View>
  )
}

export default Egaming