import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import { FlashList } from "@shopify/flash-list";
import * as queries from '../../graphql/queries';
import { Auth, API, graphqlOperation } from "aws-amplify";

const {width, height} = Dimensions.get('screen');





const ActiveList = () => {


    const navigation = useNavigation();
    const [campaigns, setCampaigns] = useState([])

    const mainColour = '#d40404';
    const deepBlueColour = '#052242';
    

    // ownerId
 
    const listingMessageRooms = async() => {
        const userplus = await Auth.currentAuthenticatedUser({bypassCache: true});
        

            const allTodos = await API.graphql({query: queries.listCampaigns, variables: 
                {
                filter : { and: [ 
                    {active: {eq: true}},
                    {approved: {eq: true}},
                    
                ]}
            } });
            
            const todos = allTodos.data.listCampaigns.items
            setCampaigns(todos)

            console.log(todos)

    }

    useEffect(() => {

        listingMessageRooms();
      }, [campaigns])
   
      


      const renderCampaigns = ({item}) => {
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('ManageCampaign', {item: item})} style={{height: 80, width: width - 30, marginBottom: 10, borderWidth: 0, borderColor: '#d1d1d1', borderRadius: 10, paddingLeft: 5, flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
                
                <View style={{width: '20%'}}>
                    <Image
                        style={{height: 70, width: 70, borderRadius: 10}}
                        source={{uri: item?.image}}
                    />
                </View>

                <View style={{height: '100%', marginLeft: 15, justifyContent: 'center', width: '65%'}}>
                  <Text style={{fontWeight: 'bold', fontSize: 12, color: deepBlueColour}}>{item.name}</Text>  
                  <Text style={{fontSize: 10, width: width / 2, textAlign: 'justify', color: 'gray'}}>{String(item?.description).slice(0, 50)}...</Text>
                </View>

                <View style={{width: '10%', alignItems: 'center', paddingRight: 15}}>
                    <Feather name='arrow-right' size={20}/>
                </View>
                
            </TouchableOpacity>
        )
      }

      

    return(
        <View style={{flex: 1, backgroundColor: '#e8e8e8'}}>
                   
           

            <View style={{backgroundColor: '#fcfcfc', width: width, marginTop: 0, padding: 15, marginBottom: 100}}>

                {/* <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 90, alignSelf: 'center'}}>
                    <Feather name="search" size={20} color='gray' style={{marginRight: 25}} />
                    <TextInput placeholder="Seach for a Campaign" placeholderTextColor={'gray'} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                </View> */}

                <View style={{width: '100%', height: '100%', paddingTop: 15}}>

                    <FlashList 
                        data={campaigns}
                        renderItem={renderCampaigns}
                        estimatedItemSize={3000}
                    />
                    </View>
            </View>


           





        </View>
    )

}
export default ActiveList