import { View, Text, TouchableOpacity, ImageBackground, Image, ScrollView, Dimensions, Pressable, FlatList, Animated, TextInput, } from 'react-native'
import React, {useState, useEffect, Fragment} from 'react';
const {width, height} = Dimensions.get('screen');
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'; 

import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { Calendar, CalendarUtils } from 'react-native-calendars';
import * as ImagePicker from 'expo-image-picker';
import { storage } from '../../config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Auth, graphqlOperation, API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';


const NewEgame = () => {

    const [opt, setOpt] = useState('Events');

    const backColour = '#e3eefa';
    const ourOrange = '#FF4F00';

    const navigation = useNavigation();

    const [campaignName, setCampaignName] = useState('')
    const [campaignDescription, setCampaignDescription] = useState('');

    const [imageone, setImageOne] = useState();
    const [imgUrlOne, setImgUrlOne] = useState();

    const [timee, setTimee] = useState('');


    const [minAmount, setMinAmount] = useState('')
    const [prize, setPrize] = useState('')
    const [numPlayers, setNumPlayers] = useState('')
    const [numGames, setNumGames] = useState('')

    const INITIAL_DATE = '2023-08-10';

    const getDate = (count: number) => {
        const date = new Date(INITIAL_DATE);
        const newDate = date.setDate(date.getDate() + count);
        return CalendarUtils.getCalendarDateString(newDate);
      };

      const [date, setDate] = useState(new Date(todayDate));
        const [datea, setDatea] = useState('')
        const todayDate = new Date;

    const renderCalendarWithMinAndMaxDates = () => {
        return (
          <Fragment>
            <Calendar
              style={{width: '100%'}}
              hideExtraDays
              current={INITIAL_DATE}
              minDate={getDate(-0)}
              maxDate={getDate(200)}
              disableAllTouchEventsForDisabledDays
              onDayPress={(ee)=> {setDate(ee.dateString), console.log(ee.dateString), setDatea(ee.dateString)}}
            />
          </Fragment>
        );
      };

      const pickImageOne = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
    
        console.log(result);
        uploadImageOne(result)
    
        if (!result.canceled) {
          setImageOne(result?.uri);
        }
      };


      const uploadImageOne = async(pickerResult)=> {


        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function(){
                resolve(xhr.response);
            };
            xhr.onerror = function(){
                reject(new TypeError('Network request failed'));
            };
            xhr.responseType = 'blob';
            xhr.open('GET', pickerResult?.uri, true);
            xhr.send(null)

            console.log(pickerResult.uri)

        });



        const datee = new Date().toISOString()

        const metadata = {
            contentType: 'image/jpeg',
        };

        const storageRef = ref(storage, datee);

        
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        console.log('Uploaded')
        getDownloadURL(snapshot.ref).then((downloadURL) => {
            setImgUrlOne(downloadURL)
            console.log('d')
        })
        }).catch((error) => {
            console.log(error.message)
        })    
       
    }


    const createCampaign = async() => {

        const userId = await Auth.currentAuthenticatedUser()


        const campaignInput = {
            name : campaignName,
            description: campaignDescription,
            image: imgUrlOne,
            active: true,
            ownerId: userId.attributes.sub,
            date: date,
            time: timee,
            numPlayers: Number(numPlayers),
            numGames: Number(numGames),
            prize: Number(prize),
            genre: 'Egames',
            minAmount: Number(minAmount)
        }

        try {
            await API.graphql(graphqlOperation(mutations.createEgames, { input: campaignInput})).then((ee)=> {
              navigation.goBack()
            })
          } catch (error) {
            console.error(error.errors)
          }

    }





  return (
    <View style={{flex: 1, backgroundColor: backColour}}>
        <View style={{height: '8%', width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Add New Event</Text>
                </Animated.View>
                
        </View>

        <ScrollView showsVerticalScrollIndicator={false}>

        <View style={{backgroundColor: '#fcfcfc', width: width, padding: 30, marginTop: 10}}>
            <View style={{borderWidth: 0, padding: 5, alignItems: 'center'}}>
                <Text style={{fontSize: 18, fontWeight: 'bold', marginBottom: 15}}>Information</Text>
            </View>

            <View>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Event Name</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, height: 45, paddingLeft: 15, width: width - 60}}>
                        <TextInput placeholder="Blue Summer Wave" placeholderTextColor={'gray'} value={campaignName} onChangeText={setCampaignName} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
            </View>

            <View style={{marginTop: 15}}>
                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Description</Text>
                <View style={{flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, height: 150, paddingLeft: 15, width: width - 60}}>
                    <TextInput placeholder="A description of the event that will give users more details and booking options" value={campaignDescription} onChangeText={setCampaignDescription} placeholderTextColor={'gray'} style={{fontSize: 12, height: '100%', width: '100%', alignItems: 'flex-start'}} multiline/>
                </View>
            </View>

            <View style={{marginTop: 15}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Number Of Players</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, height: 45, paddingLeft: 15, width: 100}}>
                        <TextInput placeholder="8" keyboardType='decimal-pad' placeholderTextColor={'gray'} value={numPlayers} onChangeText={setNumPlayers} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
            </View>

            <View style={{marginTop: 15}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Number Of Games</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, height: 45, paddingLeft: 15, width: 100}}>
                        <TextInput placeholder="16" keyboardType='decimal-pad' placeholderTextColor={'gray'} value={numGames} onChangeText={setNumGames} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
            </View>



            <TouchableOpacity onPress={()=> {pickImageOne()}} style={{marginTop: 15}}>
                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Cover Image</Text>
                    <ImageBackground  source={{uri: imageone}} style={{height: height / 3, width: width - 60, borderWidth: 1, borderRadius: 20, marginTop: 0, alignItems: 'center', justifyContent: 'center', borderStyle: 'dashed'}}>
                        <View style={{height: 60, width: 60, backgroundColor: '#f2f2f2', borderRadius: 50, alignItems: 'center', justifyContent:'center'}}>
                            <Feather  name='image' size={20} /> 
                        </View>
                    </ImageBackground>
            </TouchableOpacity>

            <View style={{marginTop: 15}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Date</Text>
                    

                    <Fragment>
                        {renderCalendarWithMinAndMaxDates()}
                    </Fragment>

            </View>

            <View style={{marginTop: 15}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Time</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, height: 45, paddingLeft: 15, width: 100}}>
                        <TextInput placeholder="1630" keyboardType='decimal-pad' placeholderTextColor={'gray'} value={timee} onChangeText={setTimee} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
            </View>

            <View style={{marginTop: 15}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Prize</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, height: 45, paddingLeft: 15, width: 100}}>
                        <TextInput placeholder="500" keyboardType='decimal-pad' placeholderTextColor={'gray'} value={prize} onChangeText={setPrize} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
            </View>

            <View style={{marginTop: 15}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Ticket Price</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', borderWidth: 0.5, borderRadius: 10, height: 45, paddingLeft: 15, width: 100}}>
                        <TextInput placeholder="30" keyboardType='decimal-pad' placeholderTextColor={'gray'} value={minAmount} onChangeText={setMinAmount} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
            </View>


            <View style={{backgroundColor: '#fcfcfc', width: width, padding: 0, marginTop: 25}}>
                <TouchableOpacity 
                onPress={()=> {createCampaign()}}
                style={{height: 45, width: width - 60,  alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange, borderRadius: 10}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Create Event</Text>
                </TouchableOpacity>
            </View>

        </View>

                
        </ScrollView>
    </View>
  )
}

export default NewEgame