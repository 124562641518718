import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput, FlatList } from "react-native";
import React, {useState, useEffect, Fragment, useRef, useCallback, useMemo} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import LottieView from 'lottie-react-native'; 
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { Auth, graphqlOperation, API } from "aws-amplify";
import { FlashList } from "@shopify/flash-list";
import { Calendar, CalendarUtils } from 'react-native-calendars';

const {width, height} = Dimensions.get('screen');

const mainColour = 'purple'

const HeaderList = [
     
    {
        id: 0,
        item: 'Active'
    },
    {
        id: 1,
        item: 'Pending'
    }
    ,
    {
        id: 2,
        item: 'Past'
    },
    {
        id: 3,
        item: 'Other'
    },
           

]

const HeadingLis = HeaderList.map((one)=> ({
    ...one,
    ref: React.createRef()
}))

const TabIndicator = ({measureLayout, scrollX}) => {

    

    const inputRange = HeadingLis.map((_,i)=> i*width)

    const tabIndicatorWidth = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.width)
    });

    const translateX = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.x)
    })
    

    return(
        <Animated.View 
        style={{position: 'absolute', bottom: 0, height: 3, 
        width: tabIndicatorWidth, borderRadius: 2, backgroundColor: mainColour,
        transform: [{
            translateX
        }]
        }}
        />
    )
}

const Tabio = ({scrollX, onTabPress}) => {

    const [measureLayout, setMeasureLayout] = useState([])
    const containerRef = useRef();

    useEffect(() => {
      let ml = []

      HeadingLis.forEach(two => {
        two?.ref?.current?.measureLayout(
            containerRef.current,
            (x, y, width, height) => {
                ml.push({
                    x, y, width, height
                })

                if(ml.length === HeadingLis.length){
                    setMeasureLayout(ml)
                }
            }
        )
      })
    }, [containerRef.current])
    
    


    return(
        <View
            ref={containerRef}
            style={{flex:1, flexDirection: 'row', }}
        >
           

            {/* <TabIndicator/> */}

            {measureLayout.length > 0 && <TabIndicator measureLayout={measureLayout} scrollX={scrollX} />}

            {/* Tabs */}
            {HeadingLis.map((item, index) => {
                return(
                    <TouchableOpacity 
                        key={`Tab-${index}`} 
                        ref={item.ref}
                        style={{flex: 1,
                        paddingHorizontal: 15,
                        alignItems: 'center',
                        justifyContent: 'center'
                        }}
                        onPress={()=> {
                            onTabPress(index)
                        }}
                    >
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item?.item}</Text>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}





const TicketEvent = ({name}) => {


    const navigation = useNavigation();
    

    const [allQueries, setQueries] = useState([]);
    const [eventName, setEventName] = useState('');
    const [city, setCity] = useState();
    const [quantity, setQuantity] = useState();
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [Headline, setHeadline] = useState('');
    const [timee, setTimee] = useState('');

    const todayDate = new Date;

    const [selected, setSelected] = useState(INITIAL_DATE);
        const [currentMonth, setCurrentMonth] = useState(INITIAL_DATE);
        const [date, setDate] = useState(new Date(todayDate));
        const [datea, setDatea] = useState('')

        const [toOpt, setToOpt] = useState()

    const AddNewEventMain = useRef(new Animated.Value(width)).current;
    const AddNewEventMainOpacity = useRef(new Animated.Value(0)).current;
    const AddNewEventSmall = useRef(new Animated.Value(width)).current;


    const deleteMainCover = useRef(new Animated.Value(-height)).current;
    const deleteMainOpac = useRef(new Animated.Value(0)).current;

    const deleteButton = useRef(new Animated.Value(-height)).current;

    const INITIAL_DATE = '2023-03-10';

    const getDate = (count: number) => {
        const date = new Date(INITIAL_DATE);
        const newDate = date.setDate(date.getDate() + count);
        return CalendarUtils.getCalendarDateString(newDate);
      };

      const onDayPress = useCallback((day) => {
        setSelected(day.dateString);
      }, []);

      const renderCalendarWithMinAndMaxDates = () => {
        return (
          <Fragment>
            <Calendar
              style={{width: '80%'}}
              hideExtraDays
              current={INITIAL_DATE}
              minDate={getDate(-0)}
              maxDate={getDate(200)}
              disableAllTouchEventsForDisabledDays
              onDayPress={(ee)=> {setDate(ee.dateString), console.log(ee.dateString), setDatea(ee.dateString)}}
            />
          </Fragment>
        );
      };


      const animDelete = () => {
        Animated.sequence([
                Animated.spring(deleteMainCover, {
                    toValue: 0,
                    useNativeDriver: true
                }),
                Animated.spring(deleteMainOpac, {
                    toValue: 1,
                    useNativeDriver: true
                }),
                Animated.spring(deleteButton, {
                    toValue: 0,
                    useNativeDriver: true
                }),
            ]).start()
    }

    const animDeleteClose = () => {
        Animated.sequence([
            Animated.spring(deleteButton, {
                toValue: -height,
                useNativeDriver: true
            }),
            Animated.spring(deleteMainOpac, {
                toValue: 0,
                useNativeDriver: true
            }),
                Animated.spring(deleteMainCover, {
                    toValue: -height,
                    useNativeDriver: true
                }),
               
                
            ]).start()
    }





    const animNewEvent = () => {
        Animated.sequence([
                Animated.spring(AddNewEventMain, {
                    toValue: 0,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventMainOpacity, {
                    toValue: 1,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventSmall, {
                    toValue: 0,
                    useNativeDriver: true
                }),
            ]).start()
    }

    const animCloseNewEvent = () => {
        Animated.sequence([
            Animated.spring(AddNewEventSmall, {
                toValue: width,
                useNativeDriver: true
            }), 
            Animated.spring(AddNewEventMainOpacity, {
                toValue: 0,
                useNativeDriver: true
            }),
             
            
            Animated.spring(AddNewEventMain, {
                    toValue: width,
                    useNativeDriver: true
                }),
               
            ]).start()
    }


    const [eventss, setEventss] = useState([])


    const listingEvents = async() => {
        const userplus = await Auth.currentAuthenticatedUser({bypassCache: true});
        
            const allTodos = await API.graphql({query: queries.listTickets, variables: {
                filter: {
                    eventId: {eq: name}
                }
            }})
            
            const todos = allTodos.data.listTickets.items
            setEventss(todos)

            console.log(todos)

    }


    useEffect(() => {
        listingEvents()
    }, [eventss])





    const emptyItems = () => {
       if(eventss?.filter(cp => !cp.product).length === 0){
        return  (
            <Animated.View style={{alignItems: 'center', backgroundColor: 'white',  marginTop: 0, flex: 1, padding: 30}}>
                <LottieView
                        autoPlay
                        style={{
                        width: width / 3,
                        height: width / 3,
                        backgroundColor: 'transparent',
                        marginTop: 0
                    }}
                        source={require('../../assets/lottie/purpledog.json')}
                        />    
                    <Text style={{alignSelf: 'center', fontSize: 16, fontWeight: 'bold', color: '#233f4d'}}>You have no events</Text>
                   
               
            </Animated.View>
        )
    } 
    }

    const SampleScreen = () => {
        return(
            <View>
                <Text>No Screen</Text>
            </View>
        )
    }


    const flatListRef = useRef();
    const scrollX = useRef(new Animated.Value(0)).current

    const onTabPress = React.useCallback(tabIndex => {
    flatListRef?.current?.scrollToOffset({
        offset: tabIndex * width
            })
        })


       
        const tabs = ()=>{
            return(
                <View style={{height: 50, width: width, borderBottomWidth: 0.5, borderColor: '#d1d1d1', borderTopWidth: 0.5, backgroundColor: '#fcfcfc'}}>
                    <Tabio scrollX={scrollX} onTabPress={onTabPress}/>    
                </View>
    
            )
        }

        const createEvent = async() => {

            const userId = await Auth.currentAuthenticatedUser()
    
    
            // const eventInput = {
            //     name : eventName,
            //     eventId: name,
            //     amount: city,
            //     description: description,
            //     qty: quantity,
            // }

            const eventInput = {
                name : eventName,
                eventId: name,
                amount: city,
                description: description,
                qty: quantity,
                content: 'null'
            }
    
    
            try {
                await API.graphql(graphqlOperation(mutations.createTicket, { input: eventInput})).then((ee)=> {
                    animCloseNewEvent()
                })
              } catch (error) {
                console.error(error)
              }
    
        }

        const deleteTicket = async() => {

            const userId = await Auth.currentAuthenticatedUser()
    
    
            const eventInput = {
                id : toOpt?.id,
            }
    
    
            try {
                await API.graphql(graphqlOperation(mutations.deleteTicket, { input: eventInput})).then((ee)=> {
                    animDeleteClose()
                })
              } catch (error) {
                console.error(error)
              }
    
              
        }



        const renderCampaigns = ({item}) => {
            return(
                <TouchableOpacity onPress={()=> {animDelete(), setToOpt(item)}} style={{height: 80, width: width - 30, marginBottom: 10, borderWidth: 0.5, borderColor: '#d1d1d1', alignSelf: 'center', borderRadius: 10, paddingLeft: 5, flexDirection: 'row', alignItems: 'center'}}>
                    
                    <View style={{width: '20%', alignItems: 'center'}}>
                       
                        <Feather name='calendar' size={40} color='gray'/>
                    </View>
    
                    <View style={{height: '100%', marginLeft: 15, justifyContent: 'center', width: '65%'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 15}}>{item.name || `--`}</Text>  
                      <Text style={{fontSize: 10, width: width / 2, textAlign: 'justify', color: 'gray'}}>{String(item?.description).slice(0, 200)} || {item?.qty} tickets</Text>
                    </View>
    
                    <View style={{width: '10%', alignItems: 'center', paddingRight: 15}}>
                        <Feather name='arrow-right' size={20}/>
                    </View>
                    
                </TouchableOpacity>
            )
          }

    return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc', paddingTop: 0}}>

            <Animated.View style={{height: height, width: width, backgroundColor: 'rgba(0,0,0,0.7)', opacity: AddNewEventMainOpacity, position: 'absolute', zIndex: 5000, transform: [{translateX: AddNewEventMain}]}}>
                    
                    <Animated.View style={{height: height /2, width: width -30, backgroundColor: '#fcfcfc', borderRadius: 20, alignSelf: 'center', marginTop: 50, paddingTop: 15, transform: [{translateX: AddNewEventSmall}]}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Animated.View style={{ borderRadius: 50, width: 30, height: 30, backgroundColor: 'black', alignItems: 'center', justifyContent:'center', left: 15, top: 0}}>
                            <TouchableOpacity onPress={()=> animCloseNewEvent()}>
                                    <Ionicons name='arrow-back' size={15} color={'#fcfcfc'} />
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View>
                                <Text style={{marginLeft: 25, fontWeight: 'bold', fontSize: 16}}>Add New Ticket</Text>
                            </Animated.View>
                        </View>
                        
                        <View style={{height: height / 2.5}}>
                            <ScrollView style={{height: '60%', width: '100%', backgroundColor: 'transparent', marginTop: 10, paddingLeft: 25}}>
                            
                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Ticket Name</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 90}}>
                                    <TextInput placeholder="General" placeholderTextColor={'gray'}  value={eventName} onChangeText={setEventName} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Amount - ZAR</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 90}}>
                                    <TextInput placeholder="Amount" placeholderTextColor={'gray'} keyboardType='number-pad' value={city} onChangeText={setCity} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Quantity</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 90}}>
                                    <TextInput placeholder="Quantity" placeholderTextColor={'gray'} keyboardType='number-pad' value={quantity} onChangeText={setQuantity} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                         
                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Ticket Description</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 90}}>
                                    <TextInput placeholder="Ticket Description" placeholderTextColor={'gray'} value={description} onChangeText={setDescription} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                                               


                            <View style={{marginTop: 25, marginBottom: 25}}>
                                <TouchableOpacity onPress={()=> createEvent()} style={{height: 45, width: width - 90,  alignItems: 'center', justifyContent: 'center', backgroundColor: 'purple', borderRadius: 20}}>
                                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Create Ticket</Text>
                                </TouchableOpacity>
                            </View>



                            </ScrollView>
                        </View>


                    </Animated.View>
            </Animated.View>

            <Animated.View style={{zIndex: 6000, position: 'absolute', width: width,  height: height, justifyContent: 'space-between', flexDirection: 'row',  opacity: deleteMainOpac, transform: [{
                translateY: deleteMainCover
            }]}}>
                
                <View style={{height: '100%', width: width, backgroundColor: 'rgba(0,0,0,0.9)', position: 'absolute'}}/>


                <TouchableOpacity onPress={()=> deleteTicket()} style={{height: 50, width: '70%', flexDirection: 'row', backgroundColor: 'red', borderRadius: 0, alignItems: 'center', justifyContent: 'center', transform: [{translateY: deleteButton}]}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 16, marginLeft: 10}}>Delete</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=> animDeleteClose()} style={{height: 50, width: '30%', flexDirection: 'row', backgroundColor: 'orange', borderRadius: 0, alignItems: 'center', justifyContent: 'center', transform: [{translateY: deleteButton}]}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 16, marginLeft: 10}}>Cancel</Text>
                </TouchableOpacity>

                

                
            </Animated.View>

            
            <View style={{height: 50, width: width, borderBottomWidth: 0, marginTop: 15, paddingLeft: 15, flexDirection: 'row'}}>
                <TouchableOpacity style={{height: 40, width: width / 3, backgroundColor: mainColour, alignItems: 'center', borderRadius: 20, justifyContent: 'center'}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Active</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{height: 40, width: width / 3, marginLeft: 10, backgroundColor: 'transparent', alignItems: 'center', borderWidth: 1, borderColor: mainColour, borderRadius: 20, justifyContent: 'center'}}>
                    <Text style={{color: mainColour, fontWeight: 'bold'}}>Past</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> animNewEvent()} style={{height: 40, width: 40, marginLeft: 10, backgroundColor: 'red', alignItems: 'center', borderWidth: 0, borderColor: mainColour, borderRadius: 40, justifyContent: 'center'}}>
                    <Feather name='plus'  color={'#fcfcfc'} size={25}  />
                </TouchableOpacity>
            </View>
            

           
            
            {emptyItems()}

            <View style={{width: '100%', height: '100%', paddingTop: 15, alignItems: 'center'}}>

                <FlatList 
                    data={eventss}
                    renderItem={renderCampaigns}
                    // estimatedItemSize={10}
                />
            </View>




        </View>
    )

}
export default TicketEvent