import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, TextInput, Dimensions, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useState, useEffect, useRef } from 'react';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import TabNav from './src/router/TabNav';


const {width, height} = Dimensions.get('screen')


import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './src/aws-exports';
Amplify.configure(awsconfig)




export default function App() {

  const [choose, setChoose] = useState('login');
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [REQValue, setREQValue] = useState('');

  const [spinning, setSpinning] = useState(false)

  const loginFunc = async() => {

    setSpinning(true)

    try {
   
      await Auth.signIn(loginValue, passwordValue).then((e)=> {
        setChoose('logged'),
        setSpinning(false)
      }
      )
    } catch (error) {
      console.error(error)
      setSpinning(false)
    }

  }


  if(choose === 'login'){

    return(
      <View style={{flex: 1, padding: 20}}>

        <View style={{marginTop: 20, marginBottom: 30}}>
                        <Text style={{fontSize: 18}}>Enter your credentials</Text>
                      </View>


                      <View style={{marginBottom: 10}}>
                        <Text style={{fontSize: 13}}>E-Mail Address</Text>
                      

                      <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
                      
                      <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                        <TextInput placeholder='email@gmail.com' style={{height: '100%', width: '100%'}} onChangeText={setLoginValue} value={loginValue} />
                      </View>

                    </View>
                      </View>

                      <View style={{marginBottom: 10}}>
                        <View style={{marginBottom: 10, marginTop: 15}}>
                        <Text style={{fontSize: 13}}>Password</Text>

                        <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
                      
                          <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                            <TextInput placeholder='Password' style={{height: '100%', width: '100%'}} onChangeText={setPasswordValue} value={passwordValue} />

                          </View>
                        </View>
                        </View>
                      </View>


                      <TouchableOpacity onPress={()=> {loginFunc()}} style={{height: 45, flexDirection: 'row', width: width - 30, borderRadius: 5, backgroundColor: '#FF4F00', alignItems: 'center', justifyContent: 'center'}}>

                        <View style={{position: 'absolute', left: 15}}>
                          {spinning ? <ActivityIndicator size={'small'} color={'#fcfcfc'} /> : <View />} 
                        </View>



                          <Text style={{color: 'white', fontSize: 13, fontWeight: 'bold', marginRight: 10}}>Login</Text>
                          <AntDesign name='arrowright' size={15} color={'white'}/>
                        </TouchableOpacity>
      
    

     

      </View>
    )

  }


  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <TabNav/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
