import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, {useState, useEffect} from 'react';
import { View } from "react-native";

import Chats from "../Screens/Chats";
import ChatConv from "../Screens/ChatConv";


const Stack = createNativeStackNavigator();

const ChatsStack = () => {

    return (
      <View style={{flex: 1}}>
        <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
            >
             <Stack.Screen 
                
                name="ChatMain">
                    {() => <Chats />}
                </Stack.Screen>   
                <Stack.Screen component={ChatConv} name="ChatConv"/>  
                
        </Stack.Navigator>
      </View>

    )
}
export default ChatsStack;
